import {inject} from '@angular/core';
import {CanActivateFn, createUrlTreeFromSnapshot} from '@angular/router';
import {APP_CONFIG, COUNTRY_DOMAIN} from '../../app-config/AppConfig';

export function countryDomainGuard(countryDomain: COUNTRY_DOMAIN | COUNTRY_DOMAIN[], negate: boolean = false): CanActivateFn {
    return route => {
        const appConfig = inject(APP_CONFIG);

        let isCountryDomain = countryDomain instanceof Array
            ? countryDomain.some(c => appConfig.isCountryDomain(c))
            : appConfig.isCountryDomain(countryDomain);

        if (negate) isCountryDomain = !isCountryDomain;

        return isCountryDomain || createUrlTreeFromSnapshot(route, ['/error']);
    };
}
