@if (user) {
    <div class="user">
        <div class="user__media">
            <div class="user__img">
                <nn-avatar [avatarId]="user.AvatarId"
                    [customerId]="user.Id"
                    [customerName]="user.name"
                    [customerLastName]="user.lastName"
                    (click)="onUploadAvatarClick()"/>
            </div>
        </div>
        <div class="user__content">
            <div class="user__title">
                <div class="user__title-inner">{{ user.name + ' ' + user.lastName }}</div>
                @if (user.isPremium) {
                    <div class="user__badge m-hide">
                        <div class="premium-badge">
                            <div class="premium-badge__text">{{ 'dashboard_page|premium_badge' | localization }}</div>
                        </div>
                    </div>
                }
            </div>
            @if (user.isPremium) {
                <div class="premium-badge m-show">
                    <div class="premium-badge__text">{{ 'dashboard_page|premium_badge' | localization }}</div>
                </div>
            }
            @if (user.isPremium) {

                <div class="user__label">{{ (user.isWorker
                    ? 'dashboard_page|worker_personal_cabinet'
                    : 'dashboard_page|employer_personal_cabinet') | localization }}
                </div>

            }
            @if (suggestPremium) {

                <a class="user__link"
                    routerLink="/buy">{{ 'dashboard_page|go_to_premium' | localization }}
                </a>

            }
        </div>
    </div>
}
