import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {EmployerVacancyApplicationsService} from '../../core/catalog/core/vacancy-application/employer-vacancy-applications.service';
import {debounceTime, startWith, switchMap, takeUntil} from 'rxjs/operators';

@Pipe({
    name: 'nnTotalUnreadVacancyApplicationsCount',
    standalone: true,
})
export class TotalUnreadVacancyApplicationsCountPipe implements PipeTransform, OnDestroy {
    private readonly destroySubject$ = new Subject<void>();
    private readonly refreshSubject$: BehaviorSubject<void> = new BehaviorSubject<void>(null as any);

    public transform(defaultValue: number): Observable<number> {
        return this.refreshSubject$.pipe(
            switchMap(() => this.applicationsService.getUnreadApplicationsCount()),
            startWith(defaultValue),
        );
    }

    constructor(private readonly applicationsService: EmployerVacancyApplicationsService) {
        applicationsService.refreshes$.pipe(
            debounceTime(500),
            takeUntil(this.destroySubject$),
        ).subscribe(this.refreshSubject$);
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }
}
