import {inject, Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {distinctUntilChanged, startWith} from 'rxjs/operators';
import {CatalogUnreadGuestsCountService} from '../../core/catalog/core/guests/catalog-unread-guests-count.service';

@Pipe({
    name: 'nnTotalUnreadGuestsCount',
    standalone: true,
})
export class TotalUnreadGuestsCountPipe implements PipeTransform {
    private readonly unreadGuestsCountService = inject(CatalogUnreadGuestsCountService);

    public transform(defaultValue: number): Observable<number> {
        return this.unreadGuestsCountService.unreadGuestsCount$.pipe(distinctUntilChanged(), startWith(defaultValue));
    }
}
